import React from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Routes from './Routes';
import { RouteDefinition } from '../models/RouteDefinitions';

export default class RouterHelper {
  public static Routes(): JSX.Element {
    return RouterHelper.RenderRoutes(Routes);
  }

  private static RenderRoutes(routes: RouteDefinition[]): JSX.Element {
    return (
      <Router basename="/" hashType="noslash">
        <Switch>
          <Route exact path="/">
            <Redirect to="/teamtab" />
          </Route>
          {routes.map((route, idx) =>
            <Route key={idx} path={route.route}>
              {route.component}
            </Route>
          )}
        </Switch>
      </Router>
    );
  }
}