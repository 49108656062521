import React from 'react';
import { Configure, Tab } from '../components';
import { RouteDefinition } from '../models/RouteDefinitions';
import { TAB_TYPES } from '../Constants'

const Routes: RouteDefinition[] = [
    {
        route: '/configure',
        component: <Configure />
    },
    {
        route: '/teamtab',
        component: <Tab tabType={TAB_TYPES.channel} />
    },
    {
        route: '/personal',
        component: <Tab tabType={TAB_TYPES.personal} />
    }
];

export default Routes;