import React, { useEffect, useState } from 'react';
import Directory from './Directory'
import Editor from './Editor'
import { getFileExtension, PrimaryButton } from './common'
import Manager from '../modules/Manager';
import { DISPLAY_STATES, ROOT_DIR } from "../Constants"
import Viewer from './Viewer'
import { Text } from '@fluentui/react-northstar'

interface Props {
  tabType: string
}

interface InitResponse {
  manager: Manager,
  connected: boolean
}

const Tab = (props: Props) => {
  // const { tabType } = props
  const [directoryInfo, setDirectoryInfo] = useState<any>()
  const [error, setError] = useState<string>('')
  const [activeFileName, setActiveFileName] = useState<string>('')
  const [editBytes, setEditBytes] = useState<string>('')
  const [manager, setManager] = useState<Manager>(new Manager())
  const [viewType, setViewType] = useState<string>('')
  const [displayState, setDisplayState] = useState<string>(DISPLAY_STATES.directory)
  const [viewSource, setViewSource] = useState<any>()

  useEffect(() => {
    const handleInit = async (m: Manager, ) => {
      setManager(m)
      m.listDir(ROOT_DIR, handleListDir, handleError)
    }
      manager.__init(handleInit, handleError)
  }, [manager])


  const handleError = (msg: string) => {
    setError(msg)
    setDisplayState(DISPLAY_STATES.error)
  }

  const handleDirClick = async (name: string) => {
    let path = directoryInfo.parent
    if (path === '/' && name) {
      path = name
    } else {
      path = `${path}/${name}`
    }

    manager.listDir(path, handleListDir, handleError)
  }

  const handleListDir = (dirInfo: any) => {
    setDirectoryInfo(dirInfo.result)
    setDisplayState(DISPLAY_STATES.directory)
  }

  const handleFileClick = async (fileName: string) => {
    const fileType = getFileExtension(fileName)
    const vs = manager.getFile(directoryInfo.parent, fileName)
    setViewSource(vs)
    setViewType(fileType || '')
    setDisplayState(DISPLAY_STATES.view)
    setActiveFileName(fileName)
  }

  const handleEdit = async (fileName: string) => {
    const decodedBytes = await manager.getBytes(directoryInfo.parent, fileName)
    if (!decodedBytes) { return }
    setEditBytes(decodedBytes)
    setDisplayState(DISPLAY_STATES.edit)
    setActiveFileName(fileName)
  }

  const handleOpenInDesktop = async (fileName: string) => {
    await manager.launch(directoryInfo.parent, fileName)
  }

  //todo notify user of safe outcome
  const saveFile = async () => {
    await manager.writeBytes(directoryInfo.parent, activeFileName, editBytes)
  }

  const clearActiveFileInfo = () => {
    setActiveFileName('')
    setViewSource('')
    setViewType('')
    setEditBytes('')
  }

  const setDisplayDefault = () => {
    setDisplayState(DISPLAY_STATES.directory)
    clearActiveFileInfo()
  }

  const navUpDir = async () => {
    const lastIdx = directoryInfo.parent.lastIndexOf('/')
    let path
    if (lastIdx === 0) {
      path = ROOT_DIR
    } else {
      const parentPath = directoryInfo.parent.slice(0, lastIdx)
      path = parentPath
    }
    manager.listDir(path, handleListDir, handleError)
  }

  const display = () => {
    switch (displayState) {
      case DISPLAY_STATES.directory:
        return <Directory
          directory={directoryInfo}
          editEnabled={manager.fsAPI.config.edit}
          handleDirClick={handleDirClick}
          handleFileClick={handleFileClick}
          handleOpenInDesktop={handleOpenInDesktop}
          handleEdit={handleEdit}
          isTeamTab={!!manager.context.groupId}
          navUpDir={navUpDir}
        />
      case DISPLAY_STATES.edit:
        return <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {PrimaryButton(() => setDisplayDefault(), "Back")}
            {PrimaryButton(() => saveFile(), "Save", { padding: '20px', paddingLeft: '10px' })}
          </div>
          <Editor setBytes={setEditBytes} bytes={editBytes} />
        </React.Fragment>
      case DISPLAY_STATES.view:
        return <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {PrimaryButton(() => setDisplayDefault(), "Back")}
          </div>
          <Viewer fileType={viewType} source={viewSource} handleViewError={setDisplayDefault} headers={[{ name: 'x-vapi-token', value: manager.fsAPI.fs_token }]} />
        </React.Fragment>
      case DISPLAY_STATES.error:
        return <Text error content={error} />
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      {display()}
    </React.Fragment>
  );
}

export default Tab