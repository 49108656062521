import * as React from 'react';
import { Menu } from '@fluentui/react-northstar';

interface MenuOption {
    icon: any,
    key: string,
    content: string,
    onClick: Function
}

const getItems = (options: MenuOption[]) => {
    const items = []
    for (const option of options) {
        items.push({
            icon: option.icon,
            key: option.key,
            content: option.content,
            onClick: () => option.onClick()
        })
    }
    return (items as any)
}

const RightClickMenu = (xPos: number, yPos: number, options: MenuOption[]) => {
    return <div style={{ position: "absolute", top: yPos, left: xPos }}><Menu items={getItems(options)} vertical /></div>
}

export default RightClickMenu;
