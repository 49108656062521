import React from 'react';
// @ts-ignore
import FileViewer from 'react-file-viewer';
import { IMAGE_TYPES } from '../Constants'
import '../styles/view.css';

interface Props {
    fileType: string,
    source: string,
    headers: any,
    handleViewError: Function
}

const ErrorComponent = (props: any) => {
    React.useEffect(() => {
        props.onError()
    })
    return <div></div>
}

const Viewer = (props: Props) => {
    const { fileType, handleViewError, headers, source } = props
    const onError = (e: any) => {
        handleViewError()
    }

    return <div style={{ height: '80vh', color: "black" }}>
        {!IMAGE_TYPES.includes(fileType.toLowerCase()) ? <FileViewer
            fileType={fileType.toLowerCase()}
            filePath={source}
            headers={headers}
            errorComponent={ErrorComponent}
            onError={onError} /> :
            <img className="image-view" src={source} alt="&nbsp;&nbsp;File Unavailable" />}
    </div >

}

export default Viewer
