import { ThemePrepared, teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-northstar';
import { TEAMS_THEMES } from '../Constants';

export default class ThemeManager {
    public static getTheme(themeStr: string | undefined): ThemePrepared {
        themeStr = themeStr || '';

        switch (themeStr) {
            case TEAMS_THEMES.dark:
                return teamsDarkTheme;
            case TEAMS_THEMES.contrast:
                return teamsHighContrastTheme;
            case TEAMS_THEMES.default:
            default:
                return teamsTheme;
        }
    }
}