import React, { useEffect, useState } from 'react';
import { Table, Text } from '@fluentui/react-northstar';
import { gridNestedBehavior } from '@fluentui/accessibility'
import { FilesGenericColoredIcon, OpenOutsideIcon, EditIcon } from '@fluentui/react-icons-northstar'
import { getFileExtension, getFileIcon, PrimaryButton } from './common'
import RightClickMenu from './RightClickMenu'
import '../styles/directory.css';
import prettyBytes from 'pretty-bytes'

import Folder from '../assets/Folder.svg';

interface Props {
    directory: any,
    editEnabled: boolean,
    handleDirClick: Function,
    handleFileClick: Function,
    handleOpenInDesktop: Function,
    handleEdit: Function,
    isTeamTab: boolean,
    navUpDir: Function
}

interface Folder {
    name: string
    group: string
    mtime: string
    size: number
    edit: boolean
}
interface File {
    name: string
    mtime: string
    size: number
    edit: boolean
}

interface ContextInfo {
    xPos: number,
    yPos: number,
    openFile: Function,
    editFile: Function
}

const Directory = (props: Props) => {
    const { directory, handleDirClick, handleFileClick, handleOpenInDesktop, handleEdit, isTeamTab, navUpDir, editEnabled } = props
    const [contextInfo, setContextInfo] = useState<ContextInfo>({ xPos: 0, yPos: 0, openFile: () => { }, editFile: () => { } })
    const [isRightClickMenuVisible, _setIsRightClickMenuVisible] = useState<boolean>(false)
    const [editVisible, setEditVisible] = useState<boolean>(false)

    const isVisibleRef = React.useRef(isRightClickMenuVisible);
    const setIsRightClickMenuVisible = (isVisible: boolean) => {
        isVisibleRef.current = isVisible;
        _setIsRightClickMenuVisible(isVisible);
    };

    useEffect(() => {
        window.addEventListener('click', handleClick)
        return () => {
            window.removeEventListener('click', handleClick);
        };
    });

    const handleContextMenu = (e: React.MouseEvent, file: File) => {
        e.preventDefault();
        setEditVisible(editEnabled && file.edit)

        let conInfo = {
            xPos: e.clientX,
            yPos: e.clientY,
            openFile: () => handleOpenInDesktop(file.name),
            editFile: () => handleEdit(file.name)
        }


        setContextInfo(conInfo);
        setIsRightClickMenuVisible(true)
    }
    const handleClick = (e: globalThis.MouseEvent) => {
        if (isVisibleRef.current) {
            setIsRightClickMenuVisible(false)
        }
    }

    const header = {
        key: 'header',
        items: [
            {
                content: <React.Fragment><FilesGenericColoredIcon /><Text styles={{ marginLeft: '10px' }} content="Name" /></React.Fragment>,
                key: 'icon'
            },
            {
                content: <Text styles={{ marginLeft: '10px' }} content="Modified Time" />,
                key: 'mTime'
            },
            {
                content: <Text styles={{ marginLeft: '10px' }} content="Size" />,
                key: 'size'
            }
        ]
    }

    const getTables = () => {
        if (directory.parent !== "/" || isTeamTab) {
            return <Table
                variables={{
                    cellContentOverflow: 'none',
                }}
                header={header}
                rows={getRows(directory.folders, directory.files)}
                aria-label="Nested navigation"
                accessibility={gridNestedBehavior} />
        }

        const personal: any[] = []
        const team: any[] = []
        const shared: any[] = []

        for (const folder of directory.folders) {
            switch (folder.group) {
                case "personal":
                    personal.push(folder)
                    break;
                case "team":
                    team.push(folder)
                    break;
                case "shared":
                    shared.push(folder)
                    break;
                default:
                    break;
            }
        }

        return <React.Fragment>
            {personal.length > 0 ? <React.Fragment>
                <h3>Personal</h3>
                <Table
                    variables={{
                        cellContentOverflow: 'none',
                    }}
                    header={header}
                    rows={getRows(personal, [])}
                    aria-label="Nested navigation"
                    accessibility={gridNestedBehavior} /></React.Fragment> : null}
            {shared.length > 0 ? <React.Fragment>
                <h3>Shared With Me</h3>
                <Table
                    variables={{ cellContentOverflow: 'none', }}
                    header={header}
                    rows={getRows(shared, [])}
                    aria-label="Nested navigation"
                    accessibility={gridNestedBehavior} /> </React.Fragment> : null}
            {team.length > 0 ? <React.Fragment>
                <h3>Teams</h3>
                <Table
                    variables={{ cellContentOverflow: 'none', }}
                    header={header}
                    rows={getRows(team, [])}
                    aria-label="Nested navigation"
                    accessibility={gridNestedBehavior} /> </React.Fragment> : null}
        </React.Fragment>


    }

    const getRows = (folders: Folder[], files: File[]) => {
        const rows: any[] = []

        for (const [idx, dir] of folders.entries()) {
            rows.push(
                {
                    key: `dir-${idx}`,
                    items: [
                        {
                            content: <div className="folder-container"><object className="folder" type="image/svg+xml" data={Folder}>...</object>
                                <Text content={dir.name} styles={{ marginLeft: '6px' }} /></div>,
                            key: `dir-${idx}-0`,
                            id: `dir-${idx}-0`,
                            onDoubleClick: () => handleDirClick(dir.name),

                        },
                        {
                            content: <Text content={(new Date(dir.mtime)).toLocaleString()} styles={{ marginLeft: '10px' }} />,
                            key: `dir-${idx}-1`,
                            id: `dir-${idx}-1`,
                            onDoubleClick: () => handleDirClick(dir.name),

                        },
                        {
                            content: <Text content={"-"} styles={{ marginLeft: '10px' }} />,
                            key: `dir-${idx}-2`,
                            id: `dir-${idx}-2`,
                            onDoubleClick: () => handleDirClick(dir.name),

                        }
                    ],
                    'aria-labelledby': `file-${idx}`,
                }
            )
        }
        for (const [idx, file] of files.entries()) {
            rows.push(
                {
                    key: `file-${idx}`,
                    items: [
                        {
                            content: <React.Fragment>{getFileIcon(getFileExtension(file.name))}<Text content={file.name} styles={{ marginLeft: '10px' }} /></React.Fragment>,
                            key: `file-${idx}`,
                            id: `file-${idx}`,
                            onDoubleClick: () => handleFileClick(file.name),

                        },
                        {
                            content: <Text content={(new Date(file.mtime)).toLocaleString()} styles={{ marginLeft: '10px' }} />,
                            key: `dir-${idx}-1`,
                            id: `dir-${idx}-1`,
                            onDoubleClick: () => handleFileClick(file.name),

                        },
                        {
                            content: <Text content={prettyBytes(file.size)} styles={{ marginLeft: '10px' }} />,
                            key: `dir-${idx}-2`,
                            id: `dir-${idx}-2`,
                            onDoubleClick: () => handleFileClick(file.name),

                        }
                    ],
                    onContextMenu: (e: React.MouseEvent) => handleContextMenu(e, file),
                    'aria-labelledby': `file-${idx}`,
                }
            )
        }

        return rows
    }

    const getRightClickOptions = (conInfo: ContextInfo) => {
        const options = [
            {
                icon: <OpenOutsideIcon />,
                key: 'open_desktop',
                content: 'Open in Desktop',
                onClick: conInfo.openFile
            }
        ]
        if (editVisible) {
            options.push({
                icon: <EditIcon />,
                key: 'edit',
                content: 'Edit',
                onClick: conInfo.editFile
            })
        }
        return options
    }

    return directory ? <React.Fragment>
        <Text content="Current Directory: " size="large" /><Text content={directory.parent} size="large" />
        {(directory.parent !== '/' && directory.parent.length > 1) ? PrimaryButton(navUpDir, "Back") : null}
        {(directory?.files?.length || directory.folders?.length) ?
            getTables()
            : <div>Empty Folder</div>}
        {isRightClickMenuVisible ? RightClickMenu(contextInfo.xPos, contextInfo.yPos, getRightClickOptions(contextInfo)) : null}
    </React.Fragment> : null
}

export default Directory