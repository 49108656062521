import VapiClient from "./VapiClient";
import { IDBStorage } from "../web-common"
import {
    ATAKAMA_NOT_RUNNING,
    GET_ENDPOINTS,
    VAPI_DB_NAME,
    STORE_NAMES,
    DB_VERSION,
    VAPI_PORTS,
    VAPI_URL
} from "../Constants"
import { encode, decode } from 'js-base64';
import * as microsoftTeams from "@microsoft/teams-js";
// @ts-ignore
import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async/dynamic'

export async function getStorage(dbName: string) {
    let vapiInfo = await IDBStorage.new(VAPI_DB_NAME,
        STORE_NAMES.AUTH_STORE, Object.values(STORE_NAMES), DB_VERSION)

    return {
        vapiInfo: vapiInfo
    }
}

interface Config {
    env: string,
    edit: boolean
}

interface FSAPI {
    fs_token: string
    fs_port: number
    api_version: string
    config: Config
}

export default class Manager {
    context: any = {}
    vapiClient: VapiClient = new VapiClient()
    port: number = VAPI_PORTS[0]
    authToken: string = ""
    fsAPI: FSAPI = {
        fs_token: '',
        fs_port: -1,
        api_version: '0',
        config: {
            env: '',
            edit: false
        }
    }
    fsHeader: any = {}
    storage!: {
        vapiInfo: IDBStorage
    }

    async getContextAsync() {
        return await new Promise((resolve) => {
            microsoftTeams.getContext((context) => {
                resolve(context)
            })
        })
    }

    async __init(successCallback: Function, errorCallback: Function) {
        this.context = await this.getContextAsync()

        const entityId = this.context?.entityId || "entityId"
        this.storage = await getStorage(entityId)

        await this.vapiClient.setup(entityId, VAPI_URL)
        const connected = await this.connect()

        if (connected) {
            successCallback(this)
        } else {
            errorCallback(ATAKAMA_NOT_RUNNING)
            this.retryConnection(successCallback)
        }
    }

    async retryConnection(successCallback: Function) {
        const retryPromise = new Promise((resolve) => {

            const interval = setIntervalAsync(async () => {
                const connected = await this.connect()
                if (connected) {
                    clearIntervalAsync(interval)
                    successCallback(this)
                    resolve()
                }
            }, 5000)
        })
        await retryPromise
    }

    //#region Setup & Connection Management
    async connect() {
        const portEstablished = await this.establishPort()
        if (!portEstablished) {
            return false
        }
        await this.establishTokens()
        return true;
    }

    async establishPort() {
        for (const port of VAPI_PORTS) {
            try {
                this.vapiClient.port = port
                const res = await this.vapiClient.get(GET_ENDPOINTS.basic_identity, { key1: "value3" }, {}, port)
                if (res.connected) {
                    return true
                } else {
                    continue
                }
            } catch (error) {
                console.log(`Port: {${port}} - Not available`)
            }
        }
        console.log('Establish Port: FAILED - No port available')
        return false
    }

    async establishTokens() {
        await this.setVapiAuthToken()
        await this.setFSAPI(true) // refresh===true for now, later refresh if expired fs api info
    }

    async setVapiAuthToken() {
        //This should also get a new token if there is one in storage but it's invalid
        this.authToken = await this.storage?.vapiInfo?.get('authToken')
        if (this.authToken) {
            return
        } else {
            const authInfo = (await this.vapiClient.get(GET_ENDPOINTS.authorize, {}, {}, this.vapiClient.port)).data
            this.authToken = authInfo.token
            await this.storage.vapiInfo.set('authToken', this.authToken)
        }
    }

    async setFSAPI(refresh = false) {
        this.fsAPI = await this.storage?.vapiInfo?.get('fsAPI')
        if (refresh || !this.fsAPI) {
            const fsAPI = (await this.vapiClient.get(GET_ENDPOINTS.connect, { token: this.authToken }, {}, this.vapiClient.port)).data
            this.fsAPI = fsAPI
            await this.storage.vapiInfo.set('fsAPI', this.fsAPI)
            this.fsHeader = { 'x-vapi-token': this.fsAPI.fs_token }
        }
    }
    //#endregion

    //#region HTTP GET endpoints
    async retryHandler(func: string, args: any, successCallback: Function, errorCallback: Function) {
        let vcArgs = [
            ...args,
            this.fsHeader,
            this.fsAPI.fs_port
        ]
        let res = await (this.vapiClient as any)[func](...vcArgs)
        if (res.connected) {
            successCallback(res.data)
        } else {
            errorCallback(res.data)
            const interval = setIntervalAsync(async () => {
                await this.connect()
                vcArgs = [
                    ...args,
                    this.fsHeader,
                    this.fsAPI.fs_port
                ]
                let res = await (this.vapiClient as any)[func](...vcArgs)
                if (res.connected) {
                    clearIntervalAsync(interval)
                    successCallback(res.data)
                }
            }, 5000)
        }
    }

    async listDir(path: string, successCallback: Function, errorCallback: Function) {
        this.retryHandler('get', [GET_ENDPOINTS.listdir, { vfs_path: path, group_id: this.context.groupId }], successCallback, errorCallback)
    }

    async getBytes(path: string, fileName: string) {
        const res = (await this.vapiClient.get(GET_ENDPOINTS.get_bytes, { vfs_path: path, name: fileName }, this.fsHeader, this.fsAPI.fs_port))
        return res?.data?.result?.bytes ? decode(res.data?.result?.bytes) : undefined
    }

    async writeBytes(path: string, fileName: string, data: string) {
        const b64Data = encode(data)
        return await this.vapiClient.get(GET_ENDPOINTS.write_bytes, { vfs_path: path, name: fileName, data: b64Data }, this.fsHeader, this.fsAPI.fs_port)
    }

    async launch(path: string, fileName: string) {
        return await this.vapiClient.get(GET_ENDPOINTS.launch, { vfs_path: path, name: fileName }, this.fsHeader, this.fsAPI.fs_port)
    }

    getFile(path: string, fileName: string) {
        return `${this.vapiClient.url}:${this.fsAPI.fs_port}/${GET_ENDPOINTS.get_file}?vfs_path=${path}&name=${fileName}&vapi_token=${encodeURIComponent(this.fsAPI.fs_token)}`
    }
    //#endregion
}
