import * as React from 'react';
import { WordIcon, ExcelIcon, FilesPictureColoredIcon, FilesTextColoredIcon, FilesPdfColoredIcon, FilesSoundIcon, FilesGenericColoredIcon, PowerPointIcon, OneNoteIcon, FilesVideoIcon } from '@fluentui/react-icons-northstar'
import { Button, Flex } from '@fluentui/react-northstar';

const iconMap = new Map([
    ['doc', <WordIcon />], 
    ['docx', <WordIcon />], 
    ['txt', <FilesTextColoredIcon />], 
    ['jpg', <FilesPictureColoredIcon />],
    ['jpeg', <FilesPictureColoredIcon />],
    ['png', <FilesPictureColoredIcon />],
    ['gif', <FilesPictureColoredIcon />],
    ['bmp', <FilesPictureColoredIcon />],
    ['mp4', <FilesVideoIcon />],
    ['webm', <FilesVideoIcon />],
    ['mp3', <FilesSoundIcon />],
    ['pdf', <FilesPdfColoredIcon />],
    ['xlsx', <ExcelIcon />], 
    ['one', <OneNoteIcon />],
    ['ppt', <PowerPointIcon />], 
    ['pptx', <PowerPointIcon />]])

export const getFileIcon = (ext: string | undefined) => {
    if (!ext || !iconMap.has(ext)) {
        return <FilesGenericColoredIcon />
    }

    return iconMap.get(ext)
}

export const getFileExtension = (fullName: string) => {
    const lastIdx = fullName.lastIndexOf('.')
    if ((lastIdx === -1) || (lastIdx === fullName.length - 1)) { return undefined }

    return fullName.substr(fullName.lastIndexOf('.') + 1)
}

export const PrimaryButton = (func: Function, content: string, styles: any = { padding: '20px', paddingLeft: '10px' }) => {
    return <Flex
        gap="gap.smaller"
        styles={() => (styles)}
    >
        <Button onClick={() => func()} primary content={content} />
    </Flex>
}