import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
    bytes: string,
    setBytes: React.Dispatch<React.SetStateAction<string>>
}

const Editor = (props: Props) => {
    const { bytes, setBytes } = props


    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['code'],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background',
        'list', 'bullet', 'indent',
        'code',
        'link', 'image'
    ]

    return (
        <ReactQuill theme="snow" modules={modules} formats={formats} value={bytes} onChange={setBytes} />
    );
}

export default Editor