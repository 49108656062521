import { IDBStorage } from '../web-common'
import { VAPI_PORTS } from '../Constants'

export default class VapiClient {
    entityId: string = ''
    port: number = VAPI_PORTS[0]
    url: string = 'http://localhost'
    authToken: string = ''
    fsAPI: any = {}
    storage!: {
        vapiInfo: IDBStorage
    }
    proxyHandler: any = {}

    constructor() {
        this.proxyHandler = {
            get: function (this: VapiClient, target: any, attr: keyof VapiClient) {
                if (this[attr]) {
                    return this[attr]
                } else {
                    return ((dict: any, headers: any, port: number) => {
                        return this.get(attr, dict, headers, port)
                    })
                }
            }.bind(this)
        }
        return new Proxy(this, this.proxyHandler)
    }

    async setup(entityId: string, url: string) {
        this.entityId = entityId
        this.url = url
    }

    // TODO discuss swapping to POST
    async get(method: string, dict: any = {}, headers: any = {}, port: number) {
        const url = new URL(`${this.url}:${port}/${method}`)
        url.search = new URLSearchParams(dict).toString();
        const req: Request = new Request(url.toString(), { method: 'GET', headers: headers })
        try {
            let res = await fetch(req)
            let js = await res.json()
            if (!res || !js || js.error) {
                return {
                    connected: true,
                    success: false,
                    data: 'Request refused.'
                }
            }
            return {
                connected: true,
                success: true,
                data: js
            }
        } catch (error) {
            return {
                connected: false,
                success: false,
                data: 'Error connecting to Atakama.  Either Atakama is not installed or not currently running.'
            }
        }
    }
}
