export const TEAMS_THEMES = {
    contrast: "contrast",
    dark: "dark",
    default: "default"
};

export const TAB_TYPES = {
    channel: "channel",
    personal: "personal"
}

export const VAPI_URL = "http://localhost"

// Todo discuss other port strategies
export const VAPI_PORTS = [2999, 52718, 63159, 56074, 62125, 64917]

export const VAPI_DB_NAME = "VAPI"
export const STORE_NAMES = {
    AUTH_STORE: 'AUTH_STORE'
}
export const DB_VERSION = 1

export const GET_ENDPOINTS = {
    authorize: "authorize",
    basic_identity: "basic_identity",
    connect: "connect",
    get_bytes: "get_bytes",
    get_file: "get_file",
    listdir: "listdir",
    launch: "launch",
    write_bytes: "write_bytes"
}

export const ROOT_DIR = '/'

export const DISPLAY_STATES = Object.freeze({
    directory: 'directory',
    edit: 'edit',
    error: 'error',
    view: 'view'
})

export const IMAGE_TYPES = ["jpg", "jpeg", 'png', 'gif', 'bmp']

export const ATAKAMA_NOT_RUNNING = "Atakama installation not detected.  Please ensure Atakama is installed and currently running."
