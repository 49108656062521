import React from 'react';
import { RouterHelper } from './router';
import { ThemeManager } from './themes'
import { Provider, ThemePrepared } from '@fluentui/react-northstar';
import * as msTeams from '@microsoft/teams-js';

export default class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
      theme: ThemeManager.getTheme('default')
    };
  }

  componentWillMount() {
    msTeams.initialize();
    msTeams.registerOnThemeChangeHandler(this.updateTheme.bind(this));
    // If you are deploying your site as a MS Teams static or configurable tab,
    // you should add “?theme={theme}” to your tabs URL in the manifest.
    // That way you will get the current theme before it’s loaded; getContext()
    // is called only after the tab is loaded, which will cause the tab to flash
    // if the current theme is different than the default.
    msTeams.getContext(context => { this.updateTheme(context.theme) });
  }

  private updateTheme(themeString: string | undefined): void {
    this.setState({
      theme: ThemeManager.getTheme(themeString)
    });
  }

  render() {
    return (
      <Provider theme={this.state.theme}>
        {RouterHelper.Routes()}
      </Provider>
    )
  }
}

interface IAppProps { }

interface IAppState {
  theme: ThemePrepared;
}